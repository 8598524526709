<template>
<div>
  <b-container fluid class="bg-white mt-4">
    <b-row class="mb-1">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Controls Taxonomy</h1>
        <p>Explore control reference standards, use filtering, sorting and search below to find the information you need.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <filter-interface class="mr-4" namespace="controls" :rows="rows" v-model="rowsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="false"></filter-interface>
      </b-col>
      <b-col cols="7">
        <div class="mb-4" style="display: flex; height: 30px;">
          <sort-interface class="mr-4" namespace="controls" :rows="rowsFiltered" v-model="rowsSorted" :optionsProperty="sortProperties"></sort-interface>
          <search-interface namespace="controls" :rows="rowsSorted" v-model="rowsSearched"></search-interface>
        </div>
        <div style="display: flex;">
          <b-pagination
            inline
            v-model="currentPage"
            :total-rows="rowsSearched.length"
            :per-page="perPage"
            class="mt-3"
          ></b-pagination>
          <b-button variant="outline-primary" @click="download" class="ml-4 button" style="height: 35px; margin-top: 12px">download in Excel</b-button>
        </div>
        <div style="background: white; height: 50vh; overflow-y: auto;  overflow-x: auto;">
          <b-tabs class="mt-1" content-class="my-4">
            <b-tab title="Simple view">
              <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
              <row :item="item"></row>
              </div>
            </b-tab>
            <b-tab title="Full controls">
              <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
              <row-full :item="item"></row-full>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import FilterInterface from '@/components/FilterInterfaceVisibleV1.vue'
import Row from '@/components/ControlSimpleSlim.vue'
import RowFull from '@/components/Control.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
import SortInterface from '@/components/SortInterfaceV1.vue'
import striptags from 'striptags'
import zipcelx from 'zipcelx'

export default {
  name: 'ControlsHome',
  components: {
    FilterInterface,
    Row,
    RowFull,
    SearchInterface,
    SortInterface
  },
  computed: {
    rows: function () {
      return this.$store.state.controls
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      perPage: 10,
      currentPage: 1,
      rowsFiltered: [],
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'created_at', text: 'Date created' },
        { value: 'updated_at', text: 'Date updated' },
        { value: 'name', text: 'Name' }
      ],
      staticDataProperties: [
        { name: 'Control Status', property: 'controlstatus', tree: true },
        { name: 'Control Themes', property: 'controlthemes', graph: 'controlthemes' },
        { name: 'Control Risks', property: 'controlrisks', graph: 'controlrisks' },
        { name: 'Control Areas', property: 'areas', graph: 'areas' },
        { name: 'Projects', property: 'projects', graph: 'projects' }
      ]
    }
  },
  created: function () {
    document.title = "KPMG Controls Reference Standards"
    console.log(this.rows)
  },
  methods: {
    download: function () {
      const data = []
      const fields = [
        { field: 'id', name: 'control id' },
        { field: 'name', name: 'control name' },
        { field: 'objective', name: 'control objective' },
        { field: 'description', name: 'control description' },
        { field: 'controlthemes', name: 'themes' },
        { field: 'controlrisks', name: 'risks' },
        { field: 'areas', name: 'responsible functions' },
        { field: 'controlstatus', name: 'control drafting status' }
      ]
      const headings = _.map(fields, field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      _.each(this.rowsSearched, annotation => {
        const row = _.map(fields, field => {
          if (field.field === 'controlstatus') {
            return { value: annotation.controlstatus.name, type: 'string' }
          }
          if (field.field === 'description') {
            return { value: striptags(annotation.description), type: 'string' }
          }
          if (field.field === 'controlthemes') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'areas') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'controlrisks') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          return { value: annotation[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'controls', sheet: { data: data } }
      zipcelx(config)
    }
  }
}
</script>

<style>
</style>
