<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="mb-1">
            <router-link :to="{ name: 'Control', params: { id: item.id }}"><strong>{{item.name}} [{{item.id}}]</strong></router-link>
            <div>{{objective}}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Control',
  props: ['item'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
  },
  data () {
    return {
      objective: _.truncate(this.item.objective, { length: 200 })
    }
  }
}
</script>

<style>
</style>
